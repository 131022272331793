import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "demo",
  style: {
    "margin-bottom": "15px",
    "margin-left": "10px"
  }
};
const _hoisted_2 = {
  class: "demo-left"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  style: {
    "width": "45%"
  }
};
const _hoisted_5 = {
  style: {
    "margin-left": "8px"
  }
};
const _hoisted_6 = {
  style: {
    "margin-left": "6px"
  }
};
const _hoisted_7 = {
  class: "bg-line",
  style: {
    "margin-left": "4px"
  }
};
const _hoisted_8 = {
  class: "line-data"
};
const _hoisted_9 = {
  class: "demo-right",
  style: {
    "margin-left": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.ssd, any => {
    return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
      src: any.img,
      style: {
        "width": "100%",
        "height": "100%",
        "background-color": "rebeccapurple"
      }
    }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(any.name), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(any.et), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
      class: "line-inner",
      style: _normalizeStyle({
        width: any.progress + 'px'
      })
    }, null, 4), _createElementVNode("div", _hoisted_8, _toDisplayString(any.beforStart) + "/" + _toDisplayString(any.validityDays), 1)])]), _createElementVNode("div", _hoisted_9, _toDisplayString(any.distanceExpirationDate) + "天后", 1)])]);
  }), 256);
}