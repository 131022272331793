import { getValidList } from '../../api/list';
export default {
  data() {
    return {
      searchKey: '',
      fireList: [],
      content: '',
      noteMail: false,
      ssd: [],
      account: {},
      fileList: [],
      // 图片预览地址
      totalPage: '',
      pageNo: 1,
      pageSize: 10,
      showImg: false,
      imgSrc: '',
      tipMsg: '',
      searchStatus: false,
      progressWidth: '100px',
      progress: '50/100'
    };
  },

  computed: {},

  created() {
    console.log('init');
    this.getListData();
    window.addEventListener('scroll', this.onScroll);
  },

  methods: {
    // 滚动触底加载
    onScroll() {
      const innerHeight = document.querySelector('#app').clientHeight; // 这三个是获取窗口高度

      const outerHeight = document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop; // if (innerHeight < (outerHeight + scrollTop)) {

      if ($(window).height() + $(window).scrollTop() + 60 > $(document).height()) {
        this.so++;
        console.log(this.so);

        if (this.totalPage > this.pageNo) {
          if (this.so % 2 === 0) {
            this.pageNo++;
            this.getListData();
          }
        } else {
          console.log('最后一页了');
          this.tipMsg = '---没有更多了---';
        }
      }
    },

    getListData() {
      if (this.searchKey != '' && this.searchKey != undefined) {
        console.log('搜索=' + this.searchKey);
        this.searchStatus = true;
        this.pageNo = 1;
      } else {
        this.searchStatus = false;
      }

      getValidList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        searchKey: this.searchKey
      }).then(res => {
        var data = res.data;
        console.log(data, this.searchKey); //显示第一页数据时清空页面

        if (this.pageNo == 1) {
          this.ssd = [];
        }

        for (var i = 0; i < data.records.length; i++) {
          this.ssd.push(data.records[i]);
        }

        this.totalPage = data.total;
      });
    }

  }
};